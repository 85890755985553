<template>
  <div class="form_page">
    <div class="left_side">
      <h2>Заполните данные для связи</h2>
      <form @submit.prevent="sendForm">
        <div class="inputs">
          <label>Имя</label>
          <input v-model="form.name" type="text" name="name" placeholder="Имя" required>
        </div>
        <div class="inputs">
          <label>Email</label>
          <input v-model="form.email" type="email" name="email" placeholder="Email" required>
        </div>
        <div class="inputs">
          <label>Телефон</label>
          <MaskInput mask="+7(###)-###-##-##" v-model="form.phone" required/>
        </div>
        <h4>{{message}}</h4>
        <div class="btn">
          <button :disabled="isActive" type="submit">Отправить</button>
          <a><p>Нажимая отправить Вы соглашаетесь с условия политики конфиденциальности</p></a>
        </div>
      </form>
    </div>
    <div class="right_side">
      <img src="@/assets/2151833346.jpg" alt="picture">
    </div>
  </div>
</template>

<script>
import { MaskInput } from "vue-3-mask";
import axios from "axios";
export default {
  components: {MaskInput},
  data()
  {
    return {
      form: {
        name: '',
        email: '',
        phone: ''
      },
      message: '',
      isActive: false
    }
  },
  methods: {
    async sendForm() {
      try {
        const response = await axios.post('api/sendform', this.form);
        this.isActive = true;
        if (response.status === 200) {
          this.message = response.data.message;
          this.form = {
            name: '',
            email: '',
            phone: ''
          }
          this.isActive = false;
          setTimeout(() => {
            this.formMessage = ''
          }, 1500)
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>

<style scoped>
.form_page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.left_side {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 64px;
  gap: 32px;
}
.left_side h2 {
  font-size: 42px;
  color: #1E0E62;
  font-weight: 700;
}
form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.inputs label {
  font-size: 14px;
  font-weight: 500;
  color: #1E0E62;
}
.inputs input {
  border: 2px solid #EBEAED;
  border-radius: 48px;
  box-sizing: border-box;
  padding: 16px;
}
.btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.btn button {
  box-sizing: border-box;
  padding: 16px 32px;
  border-radius: 48px;
  background-color: #25DAC5;
  border: none;
  color: #fff;
  transition: background-color 200ms ease;
  cursor: pointer;
}
.btn button:hover {
  background-color: #286c64;
}
.btn p {
  font-size: 14px;
  color: rgba(21, 20, 57, 0.4);
}
.right_side {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.right_side img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media all and (max-width: 700px) {
  .right_side {
    display: none;
  }
  .left_side h2 {
    font-size: 32px;
  }
}
</style>